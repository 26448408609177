<template>
  <div class="">
    <p class="head">Recently Viewed</p>
    <ul >
      <li
        v-for="(recent, index) in recentList"
        :key="`recent-${index}`"
      >{{recent}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recentList: ["Offering", "Feed"]
    };
  }
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 8px;
}
li {
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    background: #233F4B;
  }
}
.head {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 13px;
  opacity: 1;
}
</style>
