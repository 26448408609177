<template>
  <div class="searchblock">
    <transition name="fade">
      <p v-if="search.length !== 0" class="escinfo absolute text-xs right-0 opacity-75">ESC TO CLEAR</p>
      <p v-if="search.length === 0" class="escinfo absolute text-xs right-0 opacity-50">TYPE TO FOCUS & SEARCH</p>
    </transition>
    <div class="search-padding">
      <label class="search" :class="{ 'borderbottomradius0': search.length > 0 }" :for="`browseSearchInput${type}`">

        <img class="searchicon" src="./../assets/ui/magGlass.svg" alt="search">
        <input
          ref="tierlistSearchInput"
          :id="`browseSearchInput${type}`"
          v-model="search"
          @keyup.esc="clearSearch"
          @keydown.down.prevent="focusResult(0, 0)"
          :placeholder="searchPlacholderText"
          autocomplete="off"
        >
<!-- @focus="searchFocus = true"
          @blur="searchFocus = false" -->
        <transition name="fade">
          <div class="flex flex-row-reverse justify-center" :class="{ 'opacity-0': search.length === 0}">
            <p class="cross rounded-lg font-medium" @click="clearSearch">clear</p>
          </div>
        </transition>
        <div class="results">
          <div
            v-for="(item, index) in searchLimit"
            tabindex="0"
            :ref="`result`"
            class="single" :class="item.character"
            :key="item.name"
            @mousedown.prevent="scrollToItem(item.name, item.character);"
            @keyup.enter="scrollToItem(item.name, item.character); $el.blur();"
            @keydown.up.prevent="focusResult(index, -1 );"
            @keydown.down.prevent="focusResult(index, +1 );"
            @focus="searchFocus = true"
            @blur="searchFocus = false"
          >
            <p>{{item.name}}</p>
            <p class="character">{{item.character}}</p>
          </div>
          <div
            v-if="search.length > 0 && searchLimit.length === 0"
            class="single no-result"
          >
            <p>No results<span class="desktop-extra">, press esc to clear</span></p>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import data from './carddata.json';
const cardsData = data.cards;
const relicsData = data.relics;

export default {
  name: 'searchinput',
  props: {
    // type of searcg 'cards' or 'relics'
    type: {
      type: String,
      validator: (val) => ['cards', 'relics'].includes(val),
      required: true,
    },
    character: {
      type: String,
      validator: (val) => ['ironclad', 'silent', 'defect', 'watcher', 'colorless'].includes(val),
      required: true,
    },
  },
  data() {
    return {
      search: '',
      searchFocus: false,
    };
  },
  computed: {
    // assign card or relic data to search module
    searchDataAssign() {
      if (this.type === 'cards') {
        return cardsData;
      }
      return relicsData;
    },

    searchRegex() {
      if (this.search.length > 0) {
        let regex = '';
        for (let s = 0; s < this.search.length; s++) {
          if (s === 0) {
            regex += this.search.charAt(s);
          } else {
            regex += `[^${this.search.charAt(s)}]*${this.search.charAt(s)}`;
          }
        }
        regex += '.*';
        return regex;
      }
      return '';
    },

    // Filter of the whole list, all characters included
    filterAllItemsAllCharacters() {
      const regexRules = new RegExp(this.searchRegex, 'gi');
      return this.searchDataAssign.filter( (item) => {
        if (item.name.match(regexRules)) {
          return item;
        }
      });
    },

    // Filter of 'filterAllItemsAllCharacters' with specified character
    filterItemForCharacterOnly() {
      return this.filterAllItemsAllCharacters.filter( (item) => {
        // || item.character === 'any' to filter 'character' and 'any' for relics only
        if (item.character === this.character || item.character === 'any') {
          return item;
        }
      });
    },

    searchResult() {
      // return blank if no search data
      if (this.search.length === 0) {
        return '';
      }

      let filteredResult = this.filterItemForCharacterOnly;
      if (filteredResult.length > 0) {
        return filteredResult;
      }

      filteredResult = this.filterAllItemsAllCharacters;
      return filteredResult;
    },

    searchLimit() {
      return this.searchResult.slice(0, 5);
    },

    searchPlacholderText() {
      return `Search ${this.type}`;
    },
  },
  watch: {
    // search(search) {
    //   this.$emit('search', search);
    // },
  },
  methods: {
    clearSearch() {
      this.search = '';
      // scroll to top when esc as this is a new search
      document.getElementById(`browseSearchInput${this.type}`).blur();
      document.getElementById(`browseSearchInput${this.type}`).focus();
    },
    scrollToItem(itemName, itemCharacter) {
      // console.log(itemCharacter);
      // console.log(this.character);
      // the timeout is to allow for the new route to render before scrolling..
      // where the user presses a character card that is not the current character
      let timeout = 0;

      // if item selected from list is not this character navigate to character
      if (itemCharacter && itemCharacter !== 'any' && this.character !== itemCharacter) {
        // console.log('character not matched');
        let theTypeNoPlural = 'card';
        if (this.type === 'relics') {
          theTypeNoPlural = 'relic';
        }
        this.$router.push({ name: `sls-tierlist-${theTypeNoPlural}`, params: { character: itemCharacter } });
        timeout = 700;
      }
      // timeout = 0, unless a different character selected
      setTimeout(() => {
        const searchInputElement = this.$refs.tierlistSearchInput;
        searchInputElement.blur();
        const element = this.$parent.$refs[itemName][0];
        // const top = element.offsetTop;
        // window.scrollTo(0, top);
        VueScrollTo.scrollTo(element, 500, {offset: -200} );
        this.highlightitem(itemName);
      }, timeout);
    },
    focusResult(indexNumber, plusMinusOne) {
      const resultIndex = indexNumber + plusMinusOne;
      if (resultIndex > 4) {
        return;
      }
      if (resultIndex < 0) {
        this.$refs.tierlistSearchInput.focus();
        return;
      }
      this.$refs.result[resultIndex].focus();
    },
    highlightitem(itemName) {
      this.$emit('highlight', itemName);
    },
  },
  // mounted() {
  //   // if search is populated scroll to the item in search
  //   setTimeout(() => {
  //     console.log('url change');

  //     this.scrollToItem(this.search);
  //   }, 3000);
  // },
};
</script>

<style lang="scss" scoped>

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

// @include for-tablet-portrait-up {
//   font-size: 27px;
// }

.searchblock {
  display: flex;
  align-items: center;
  flex-direction: column;
  // background: #2c414b;
  width: 100%;
  // height: 64px;
  @include for-tablet-landscape-up {
    background: none;
  }
}

.search-padding {
  // padding: 9px 9px;
  width: calc(100% - 16px);
  // @include for-tablet-landscape-up {
  //   padding: 0;
  // }
 margin-top: 7px;
}

.search {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  padding: 0 6px 0 10px;
  height: 44px;

  border-radius: 8px;
  background: lighten(#3F5F69, 5%);
  background-image: linear-gradient(-90deg, #39535F 0%, #405E68 100%);
  box-shadow: rgba(#0a1c22, 0.3) 0 3px 2px 0, inset rgba(#4f7481, 1) 0 -1px 0 0, inset rgba(#4f7481, 0.5) 0 -6px 6px -3px;
  // border: 1px solid lighten(#03053b, 15%);
  // border: 2px solid #2a3e44;
  // box-shadow: #c9bf92 0 0 0 2px;
  transition: .25s;

  @include for-tablet-landscape-up {
    grid-template-columns: auto 1fr;
    background-image: none;
    border: 0px solid #2a3e44;
    box-shadow: #c9bf92 0 0 0 0;
    border-radius: 10px;
    // height: 40px;
    padding: 0 4px 0 18px;
  }


  &:focus-within {
    // background: lighten(#2a3e44, 10%);
    background-image: linear-gradient(-90deg, rgb(77, 111, 128) 0%, rgb(75, 109, 121) 100%);
    // border: 2px solid lighten(#2a3e44, 10%);
    // border: 5px solid lighten(#8C866C, 20%);



    @include for-tablet-landscape-up {
      background-image: none;
      border: 0px solid #2a3e44;
      box-shadow: #c9bf92 0 0 0 0;
    }

    .results {
      display: block !important;
    }

    .borderbottomradius0 {
      transition: 0s ease-in-out;
      // @include for-tablet-landscape-up {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      // }
    }
  }

  > .searchicon {
    margin: 0;
    height: 22px;
    width: 22px;
    //old svg -> stlyes
    // height: 34px;
    // width: 34px;
    @include for-tablet-landscape-up {
      display: none;
    }

  }

  > input {
    // margin-left: 20px;
    display: block;
    background: none;
    border: none;
    outline: none;
    color: #fff6e1;
    text-shadow: 1px 1px 0 rgba(#000000, 0.3);
    width: 100%;
    font-size: 22px;
    caret-color: #ede688;

    @include for-tablet-landscape-up {
      font-size: 16px;
    }


    &::placeholder {
      //color: lighten(#3F5F69, 15%);
      color: rgba(#dfd9bf, 0.75);
      text-shadow: 0 0 0 rgba(#000000, 0.3);
      margin-left: 18px;
    }
  }

  .opacity-0 {
    opacity: 0;
  }


  > div {
    // transform: scale(1,1);
    opacity: 1;
    transition: 0.3s ease-in-out;
    > p {
      text-align: center;
      margin: 0 auto;
      padding: 0;
    }
    .cross{
      // font-weight: 400;
      padding: 5px 12px;
      // width: 30px;
      height: 30px;
      background: rgba(#fff, 0.12);
      font-size: 14px;
      text-transform: uppercase;
      transition: 0.25s;
      text-shadow: 1px 1px 0 rgba(#000000, 0.3);

      @include for-tablet-landscape-up {
        font-size: 18px;
        margin: 0;
        &:hover {
          color: #f0c944;
          transform: scale(1.2);
          text-shadow: 1px 1px 1px rgba(#000000, 0.3);
        }
      }


    }
    .esc {
      text-transform: uppercase;
      font-size: 12px;
      opacity: 0;
      display: none;
      @include for-tablet-landscape-up {
        display: block;
      }
      // display:none;
    }
  }
}

.escinfo {
  position: absolute;
  top: -22px;
}

// .borderbottomradius0 {
//   transition: 0s ease-in-out;
//   @include for-tablet-landscape-up {
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//   }
// }

.results {
  display:none;
  width: calc(100% - 16px);
  box-shadow: 0 6px 6px 0 rgba(#000000, 0.25);
  position: absolute;
  background: #19272b;
  // margin: 9px 9px;

  // padding-top: 1px;
  // top: 64px;
  // left: 0;
  // @include for-tablet-landscape-up {
    top: 50px;
    left: 8px;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
    // padding-top: 0;
  // }
  // margin: 0 6px;


  .single {
    padding: 8px 18px;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    width: 100%;
    @include for-tablet-landscape-up {
      font-size: 16px;
      padding: 6px 18px;
    }

    &:last-child {
      padding-bottom: 10px;

      @include for-tablet-landscape-up {
        padding-bottom: 8px;
      }
    }

    // &:hover, &:focus {
    //   background: rgb(86, 127, 141);
    // }

    .character {
      font-size: 11px;
      // height: 20px;;
      text-transform: uppercase;
      font-weight: 400;
      background: rgba(255,255,255, 0.15);
      padding: 2px 10px;
      margin: 0;
      border-radius: 30px;
    }
  }

  .ironclad {
    background: #532C32;
    border-bottom: 1px solid #412226;
    &:hover, &:focus {
      background: rgb(107, 39, 49);
    }
  }

  .silent {
    background: #36462F;
    &:hover, &:focus {
      background: rgb(69, 133, 56);
    }
  }

  .defect {
    background: #233F4B;
    &:hover, &:focus {
      background: rgb(34, 101, 146);
    }
  }

  .watcher {
    background: rgb(74, 44, 83);
    border-bottom: 1px solid #412226;
    &:hover, &:focus {
      background: rgb(80, 39, 107);
    }
  }

  .colorless {
    background: #404040;
    &:hover, &:focus {
      background: rgb(116, 116, 116);
    }
  }
  .any {
    background: #324c53;
    &:hover, &:focus {
      background: rgb(65, 98, 109);
    }
  }
}

.no-result {
  background: #2a3e44;
  .desktop-extra {
    display: none;
    @include for-tablet-landscape-up {
      display: inline;
    }
  }
}



.fade-enter-active {
  transition: all 50ms ease-in;
  // transition: bounce 200ms ;
}
.fade-leave-active {
  transition: all 150ms linear;
  opacity: 1;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


// .zoom-enter-active {
//   animation: zoom .5s;
// }
// .zoom-leave-active {
//   animation: zoom .5s reverse;
// }

// // .zoom-enter, .zoom-leave-to {
// //   transform: scale(0,0);
// // }

// @keyframes zoom {
//   0% {
//     transform: scale(0);
//   }
//   50% {
//     transform: scale(1.2);
//   }
//   100% {
//     transform: scale(1);
//   }
// }
</style>

